import { CboRole, FeatureFlag, Package, Req, Res } from '@cbo/shared-library';
import { Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import 'dayjs/locale/es-mx';
import 'dayjs/locale/fr-ca';
import { changeLanguage } from 'i18next';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  Suspense,
  createContext,
  lazy,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Alert from '@mui/material/Alert';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { convertRegionToDayJsLocale } from '../countryAndRegionData';
import HomePage from '../shared/admin/HomePage/HomePage';
import NotificationsDialog from '../shared/admin/NotificationsDialog/NotificationsDialog';
import { NotificationPriority } from '../shared/admin/models/NotificationSettings';
import { proxies } from '../shared/admin/requests';
import {
  useCompanyLinksDataQuery,
  useNotificationsDataQuery,
  useUserPreferencesDataQuery,
} from '../shared/admin/requests/queries';
import RequirePermission from '../shared/auth/RequirePermission/RequirePermission';
import AppBar from '../shared/components/AppBar/AppBar';
import NotificationRightPanel from '../shared/components/RightPanel/Notifications/NotificationsRightPanel/NotificationsRightPanel';
import AppSidebar from '../shared/components/SidebarNavigation/AppSidebar';
import { CustomV4Sidebar } from '../shared/components/SidebarNavigation/ncr-design-system-components/CustomSidebar/CustomSideBar';
import { useSidebar } from '../shared/components/SidebarNavigation/ncr-design-system-components/CustomSidebar/CustomSidebarContext';
import * as DemoComponents from '../shared/components/UxDemo/UxDemo';
import { NotFoundDemo } from '../shared/components/UxDemo/UxDemo';
import routes from '../shared/constants/routes';
import { useSnackbar } from '../shared/contexts/SnackbarContext';
import { useAccountPreferences } from '../shared/contexts/accountPreferencesContext';
import { useCompanyLinks } from '../shared/contexts/companyLinksContext';
import defaultPreferences from '../shared/contexts/defaultPreferences';
import { useNotifications } from '../shared/contexts/notificationContext';
import { useUsers } from '../shared/contexts/userContext';
import ErrorFallback from '../shared/errors/ErrorFallback';
import LaborRulesPage from '../shared/laborRules/LaborRulesPage/LaborRulesPage';
import { Nullable } from '../shared/models/Nullable';
import { useOrgContext } from '../shared/org/CommonComponentWrapper';
import useCallBsl from '../shared/utils/hooks/useCallBsl';
import { useFeatureFlags } from '../shared/utils/hooks/useFeatureFlag';
import usePrefetchReportData from '../shared/utils/hooks/usePrefetchReportData';
import { getStoredLang, setStoredLang } from '../shared/utils/langUtils/langUtils';
import PackagePermissionWrapper from '../shared/auth/PackagePermission/PackagePermission';
import { usePackages } from '../shared/contexts/packageContext';
import HomePageNewApi from '../shared/admin/HomePage/HomePageNewApi';

const Verb = Req.Firebase.HttpVerb;

export type RightPanels = 'notifications' | 'laborPanel';

interface ShellProps {
  apiRoot: string;
  ncridUrl: string;
}

const AccountPreferencesPage = lazy(() => import('../shared/admin/AccountPreferences/AccountPreferencesPage'));
const AuditActivityLoggingPage = lazy(
  () => import('../shared/admin/AuditActivityLoggingPage/AuditActivityLoggingPage')
);
const JobsPage = lazy(() => import('../shared/labor/Jobs/JobsPage'));
const JobsFocusMode = lazy(() => import('../shared/labor/JobFocusMode/JobFocusMode'));
const EmployeeRegistrationPage = lazy(
  () => import('../shared/labor/EmployeeRegistrationPageNew/EmployeeRegistrationPage')
);
const DayPartsPage = lazy(() => import('../shared/admin/DayParts/DayPartsPage'));
const EditLinksPage = lazy(() => import('../shared/admin/EditLinksPage/EditLinksPage'));
const FiscalCalendarPage = lazy(() => import('../shared/admin/FiscalCalendarPage/FiscalCalendarPage'));
const SiteGroupsPage = lazy(() => import('../shared/admin/SiteGroupsPage/SiteGroupsPage'));
const NotificationSettingsPage = lazy(() => import('../shared/admin/NotificationSettings/NotificationSettingsPage'));
const PayrollCalendarPage = lazy(() => import('../shared/admin/PayrollCalendarPage/PayrollCalendarPage'));
const SitesPage = lazy(() => import('../shared/admin/Sites/SitesPage'));
const SiteSettingsPage = lazy(() => import('../shared/admin/SiteSettingsPage/SiteSettingsPage'));
const SiteTagsPage = lazy(() => import('../shared/admin/SiteTagsPage/SiteTagsPage'));
const InventoryHomePage = lazy(() => import('../shared/inventory/InventoryHome'));
const LaborHomePage = lazy(() => import('../shared/labor/LaborHome'));
const MySchedulePage = lazy(() => import('../shared/labor/Scheduling/MySchedulePage/MySchedulePage'));
const SalesHomePage = lazy(() => import('../shared/sales/SalesHome'));
const SalesSettingsHomePage = lazy(() => import('../shared/sales/SalesSettings/SalesSettingsHome'));
const PlaceholderPage = lazy(() => import('./PlaceholderPage'));
const GeneralLedgerAccountsDashboard = lazy(
  () => import('../shared/sales/GeneralLedgerAccounts/GeneralLedgerAccountsDashboard')
);
const GeneralLedgerAccountMappingPage = lazy(
  () => import('../shared/admin/GeneralLedgerAccountMapping/GeneralLedgerAccountMappingPage')
);

type ShellContextValue = {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  openRightPanel: Nullable<RightPanels>;
  setRightPanel: Dispatch<SetStateAction<Nullable<RightPanels>>>;
};
const ShellContext = createContext<ShellContextValue>({
  sidebarOpen: true,
  setSidebarOpen: () => {
    // throw new Error('Not implemented');
  },
  openRightPanel: null,
  setRightPanel: () => {
    // throw new Error('Not implemented');
  },
});

function ShellContextProvider({ children }: { children: ReactNode }) {
  const { setCollapsed } = useSidebar();
  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(true);
  const [openRightPanel, setRightPanel] = React.useState<Nullable<RightPanels>>(null);
  const { pathname: currentUrl } = useLocation();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  usePrefetchReportData();

  useEffect(() => {
    // Close sidebar when we click into focus mode
    if (
      [
        routes.EMPLOYEE_OVERVIEW_FOCUSMODE_BASE,
        routes.HOUSE_ACCOUNT_FOCUSMODE_BASE,
        routes.JOBCODE_CONFIGURATION_FOCUSMODE_BASE,
      ].some((focusModePath) => currentUrl.includes(focusModePath))
    ) {
      setCollapsed(false);
    }
  }, [currentUrl, isMobile, setCollapsed]);

  const value = useMemo(
    () => ({
      isMobile,
      sidebarOpen,
      setSidebarOpen,
      openRightPanel,
      setRightPanel,
    }),
    [isMobile, sidebarOpen, setSidebarOpen, openRightPanel, setRightPanel]
  );

  return <ShellContext.Provider value={value}>{children}</ShellContext.Provider>;
}
export const useShell = () => useContext(ShellContext);

function Routes() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [
    cboLaborRulesEnabled,
    cboGeneralLedgerAccounts,
    cboSiteTagsEnabled,
    cboSiteGroupsEnabled,
    cboLaborRearchitecture,
  ] = useFeatureFlags(
    FeatureFlag.laborRules,
    FeatureFlag.generalLedgerAccounts,
    FeatureFlag.siteTags,
    FeatureFlag.siteGroups,
    FeatureFlag.laborRearchitecture
  );

  return (
    <Switch>
      <Route exact path={routes.HOME_PAGE} key='home-page'>
        {cboLaborRearchitecture ? <HomePageNewApi /> : <HomePage />}
      </Route>
      <Route exact path={routes.DEMO_ITEMS} key='demo-items'>
        <DemoComponents.Page />
      </Route>
      <Route path={routes.NOT_AUTHORIZED} key='not-authorized'>
        <PlaceholderPage contentText='Not authorized' dataTestId='not-authorized-page' />
      </Route>
      <Route path={routes.EMPLOYEE} key='employee'>
        <PlaceholderPage contentText='Employee Page' dataTestId='employee-page' />
      </Route>
      <Route path={routes.MANAGER} key='manager'>
        <PlaceholderPage contentText='Manager Page' dataTestId='manager-page' />
      </Route>
      <Route path={routes.ACCOUNT_PREFERENCES} key='account-preferences'>
        <RequirePermission permissions={[]} requireAuth>
          <AccountPreferencesPage />
        </RequirePermission>
      </Route>

      {cboLaborRearchitecture && (
        <Route path={routes.NOTIFICATION_SETTINGS} key='notifications-settings'>
          <RequirePermission permissions={[CboRole.NOTIFICATION_GROUP_EDIT]}>
            <NotificationSettingsPage />
          </RequirePermission>
        </Route>
      )}

      {cboLaborRearchitecture && (
        <Route path={routes.EMPLOYEE_REGISTRATION_HOME} key='employee-registration'>
          <RequirePermission permissions={[CboRole.EMPLOYEE_EDIT, CboRole.EMPLOYEE_PERSONAL_EDIT]}>
            <EmployeeRegistrationPage />
          </RequirePermission>
        </Route>
      )}

      <Route path={routes.EDIT_COMPANY_LINKS} key='edit-company-links'>
        <RequirePermission permissions={[CboRole.LINK_ADMIN]}>
          <EditLinksPage />
        </RequirePermission>
      </Route>

      <Route path={routes.SITE_SETTINGS} key='site-settings'>
        <RequirePermission permissions={[CboRole.SITE_VIEW, CboRole.SITE_EDIT]}>
          <SiteSettingsPage />
        </RequirePermission>
      </Route>

      <Route path={routes.FISCAL_CALENDAR} key='fiscal-calendar'>
        <RequirePermission permissions={[CboRole.FISCAL_CALENDAR_VIEW, CboRole.FISCAL_CALENDAR_EDIT]}>
          <FiscalCalendarPage />
        </RequirePermission>
      </Route>

      <Route path={routes.PAYROLL_CALENDAR} key='payroll-calendar'>
        <RequirePermission permissions={[CboRole.PAYROLL_CALENDAR_VIEW, CboRole.PAYROLL_CALENDAR_EDIT]}>
          <PayrollCalendarPage />
        </RequirePermission>
      </Route>

      {cboSiteGroupsEnabled && (
        <Route path={routes.SITE_GROUPS} key='site-groups'>
          <PackagePermissionWrapper packagesRequired={[Package.STARTER]}>
            <RequirePermission permissions={[CboRole.SITE_GROUP_VIEWER, CboRole.SITE_GROUP_EDITOR]}>
              <SiteGroupsPage />
            </RequirePermission>
          </PackagePermissionWrapper>
        </Route>
      )}
      <Route path={routes.SITES} key='sites'>
        <RequirePermission permissions={[CboRole.SITE_VIEW, CboRole.SITE_EDIT]}>
          <SitesPage />
        </RequirePermission>
      </Route>

      {cboSiteTagsEnabled && (
        <Route path={routes.SITE_TAGS} key='site-tags'>
          <PackagePermissionWrapper packagesRequired={[Package.STARTER]}>
            <SiteTagsPage />
          </PackagePermissionWrapper>
        </Route>
      )}

      <Route path={routes.DAY_PARTS} key='day-parts'>
        <PackagePermissionWrapper packagesRequired={[Package.STARTER]}>
          <RequirePermission permissions={[CboRole.SITE_DAY_PART_VIEW, CboRole.SITE_DAY_PART_EDIT]}>
            <DayPartsPage />
          </RequirePermission>
        </PackagePermissionWrapper>
      </Route>

      <Route path={routes.ACTIVITY_LOG} key='activity-log'>
        <RequirePermission permissions={[CboRole.AUDIT_LOG_VIEW]}>
          <AuditActivityLoggingPage />
        </RequirePermission>
      </Route>

      {cboLaborRulesEnabled && cboLaborRearchitecture && (
        <Route path={routes.LABOR_RULES} key='labor-rules'>
          <PackagePermissionWrapper packagesRequired={[Package.LABOR_PAYROLL]}>
            <RequirePermission permissions={[CboRole.LRE_RULE_WRITER]}>
              <LaborRulesPage />
            </RequirePermission>
          </PackagePermissionWrapper>
        </Route>
      )}

      {cboLaborRearchitecture && [
        <Route path={routes.JOBCODE_CONFIGURATION_DETAILS} key='jobcode-configuration-details'>
          <RequirePermission permissions={[CboRole.JOBCODE_CONFIG_EDIT]}>
            <JobsFocusMode />
          </RequirePermission>
        </Route>,

        <Route path={routes.JOBCODE_EMPLOYEES_OVERVIEW_PAGE} key='jobcode-employee-overview'>
          <RequirePermission permissions={[CboRole.JOBCODE_CONFIG_VIEW]}>
            <JobsFocusMode />
          </RequirePermission>
        </Route>,

        <Route path={routes.JOBCODE_CONFIGURATION_PAGE} key='jobcode-configuration'>
          <RequirePermission permissions={[CboRole.JOBCODE_CONFIG_VIEW]}>
            <JobsPage />
          </RequirePermission>
        </Route>,
      ]}

      {cboGeneralLedgerAccounts && [
        <Route key='gl-dash' path={routes.MANAGE_GL_ACCOUNTS}>
          <PackagePermissionWrapper packagesRequired={[Package.ADVANCED_ANALYTICS]}>
            <RequirePermission permissions={[CboRole.GL_ACCOUNTS_READ]}>
              <GeneralLedgerAccountsDashboard />
            </RequirePermission>
          </PackagePermissionWrapper>
        </Route>,
        <Route key='gl-map' path={routes.GL_ACCOUNT_MAPPING}>
          <PackagePermissionWrapper packagesRequired={[Package.ADVANCED_ANALYTICS]}>
            <RequirePermission permissions={[CboRole.GL_ACCOUNTS_READ]}>
              <GeneralLedgerAccountMappingPage />
            </RequirePermission>
          </PackagePermissionWrapper>
        </Route>,
      ]}

      <Route path={routes.SALES_SETTINGS} key='sales-settings'>
        <SalesSettingsHomePage />
      </Route>

      <Route path={routes.LABOR_MODULE} key='labor'>
        <LaborHomePage />
      </Route>
      <Route path={routes.INVENTORY_MODULE} key='inventory'>
        <InventoryHomePage />
      </Route>
      <Route path={routes.SALES} key='sales'>
        <SalesHomePage />
      </Route>
      <Route path={routes.MY_SCHEDULE} key='my-schedule'>
        <RequirePermission permissions={[CboRole.SCHEDULE_PERSONAL_VIEW]}>
          <MySchedulePage isMobile={isMobile} />
        </RequirePermission>
      </Route>
      <Route path='*' key='not-found'>
        <NotFoundDemo />
      </Route>
    </Switch>
  );
}

export function OldShell(props: ShellProps) {
  const { apiRoot, ncridUrl } = props;
  const { t } = useTranslation();
  const callBsl = useCallBsl();
  const { setRightPanel, openRightPanel } = useShell();
  const handleRightPanelClose = () => setRightPanel(null);
  const user = useUsers();
  const { setSnackbarState } = useSnackbar();
  const { setSidebarLinks } = useCompanyLinks();
  const notificationContext = useNotifications();
  const { organization } = useOrgContext();
  const { packagesSet } = usePackages();
  const { setPreferences, setPreferencesLoading } = useAccountPreferences();
  const [isLoadingTokenExchange, setIsLoadingTokenExchange] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [notifications, setNotifications] = useState<Res.Notifications.Notification[]>([]);
  const [notificationsDialogOpen, setNotificationsDialogOpen] = useState(false);
  const [notificationsPerPage, setNotificationsPerPage] = useState(0);
  const [notificationPage, setNotificationPage] = React.useState(1);
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [lastPage, setLastPage] = React.useState(0);
  const [adapterLocale, setAdapterLocale] = React.useState('en');
  const [userPreferencesAlertOpen, setUserPreferencesAlertOpen] = React.useState(false);
  const [notificationAlert, setNotificationAlert] = React.useState(false);
  const { pathname: currentUrl } = useLocation();

  const { data: allNotifications, isError: isGetNotificationError } = useNotificationsDataQuery(
    user.fullyAuthenticated === 'authenticated'
  );

  const handleNotificationsPageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setNotificationPage(value);
  };

  const handleNotificationsOpen = () => {
    handleRightPanelClose();
    setNotificationPage(1);
    setNotificationsDialogOpen(true);
  };

  const handleNotificationsClose = () => {
    setNotificationsDialogOpen(false);
  };

  const {
    data: userPreferencesData,
    isLoading: isUserPreferencesLoading,
    isError: isUserPreferencesError,
  } = useUserPreferencesDataQuery(user.fullyAuthenticated === 'authenticated');
  const preferencesToSet: Res.Admin.UserPreferences =
    userPreferencesData && Object.keys(userPreferencesData).length > 0 ? userPreferencesData : defaultPreferences;
  const usingDefaultPreferences = !(userPreferencesData && Object.keys(userPreferencesData).length > 0);

  // Get Company Links
  const {
    data: sidebarLinksData,
    isLoading: isSidebarLinksLoading,
    isError: isGetSidebarLinksError,
  } = useCompanyLinksDataQuery(user.fullyAuthenticated === 'authenticated', true);

  // Process Okta state login
  useEffect(() => {
    setIsLoadingTokenExchange(user.oktaStatus === 'authenticated' && user.firebaseStatus !== 'authenticated');
  }, [user.oktaStatus, user.firebaseStatus, user.fullyAuthenticated]); // Update if oktaAuthState is authenticated changes

  useEffect(() => {
    async function sendFCMToken(fcmToken: string): Promise<void> {
      await callBsl({
        proxy: proxies.notifications,
        verb: Verb.POST,
        pathSegments: ['fcm-token'],
        payload: {
          fcmToken,
        },
      });
    }

    if (user.fcmToken) {
      sendFCMToken(user.fcmToken);
    }
  }, [user.fcmToken, apiRoot, callBsl]);

  // Setting the language
  useEffect(() => {
    setPreferencesLoading(isUserPreferencesLoading);
    async function setLanguageFromPreferences(): Promise<void> {
      if (user.fullyAuthenticated === 'authenticated') {
        if (!usingDefaultPreferences) {
          await changeLanguage(preferencesToSet.language);
          setStoredLang(preferencesToSet.language);
        } else {
          await changeLanguage(getStoredLang());
        }
        setPreferences(preferencesToSet);
      }
    }

    setLanguageFromPreferences();
  }, [
    isUserPreferencesLoading,
    preferencesToSet,
    usingDefaultPreferences,
    preferencesToSet.language,
    setPreferences,
    setPreferencesLoading,
    user?.fullyAuthenticated,
  ]);

  useEffect(() => {
    const formattedLocale = convertRegionToDayJsLocale(preferencesToSet.region);
    setAdapterLocale(formattedLocale);
  }, [preferencesToSet]);

  useEffect(() => {
    if (!isSidebarLinksLoading && user.fullyAuthenticated === 'authenticated') {
      if (isGetSidebarLinksError) {
        setSnackbarState({ open: true, message: `Error fetching company links`, color: 'error' });
      }
      setSidebarLinks(sidebarLinksData ?? null);
    }
  }, [sidebarLinksData, isSidebarLinksLoading, setSidebarLinks, user, isGetSidebarLinksError, setSnackbarState]);

  useEffect(() => {
    if (isUserPreferencesError && !isUserPreferencesLoading && ['/'].includes(currentUrl)) {
      setUserPreferencesAlertOpen(true);
    } else {
      setUserPreferencesAlertOpen(false);
    }
  }, [isUserPreferencesError, isUserPreferencesLoading, currentUrl]);

  useEffect(() => {
    if (isGetNotificationError && ['/'].includes(currentUrl)) {
      setNotificationAlert(true);
    } else {
      setNotificationAlert(false);
    }
  }, [isGetNotificationError, currentUrl]);
  useEffect(() => {
    if (Array.isArray(allNotifications)) {
      const hasUnread = allNotifications.some((notification) => !notification.read);
      setHasUnreadNotifications(hasUnread);
      setNotifications(allNotifications);
    }
  }, [allNotifications, setNotifications, setHasUnreadNotifications]);

  useEffect(() => {
    if (notificationContext.broadcastChannel === null) {
      return;
    }
    notificationContext.broadcastChannel.onmessage = (payload) => {
      notificationContext.setNewNotification(payload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationContext.broadcastChannel, notificationContext.setNewNotification]);

  useEffect(() => {
    if (notificationContext.newNotification === null) {
      return;
    }
    setSnackbarState({
      open: true,
      message: notificationContext.newNotification.data?.description,
      color: 'info',
      notificationPriority:
        notificationContext.newNotification.data?.priority === 'true'
          ? NotificationPriority.Priority
          : NotificationPriority.Normal,
    });
  }, [notificationContext.newNotification, setSnackbarState]);

  useEffect(() => {
    const shouldRemoveLastPage = notifications?.length <= notificationsPerPage * (numberOfPages - 1);
    const hasMoreThanOnePage = numberOfPages > 1;

    if (hasMoreThanOnePage && shouldRemoveLastPage) {
      setNumberOfPages(numberOfPages - 1);
      setLastPage(lastPage - 1);

      if (notificationPage === lastPage) {
        setNotificationPage(notificationPage - 1);
      }
    }
  }, [notifications, notificationPage, notificationsPerPage, numberOfPages, lastPage]);

  useEffect(() => {
    sessionStorage.setItem('showLogin', 'false');
  }, []);
  return (
    <CustomV4Sidebar
      side='left'
      width={240}
      content={<AppSidebar ncridUrl={ncridUrl} />}
      slotProps={{
        sidebar: {
          sx: { zIndex: 1200 },
        },
      }}
    >
      <Drawer
        anchor='right'
        open={!!openRightPanel}
        onClose={() => setRightPanel(null)}
        PaperProps={{
          sx: {
            width: isMobile ? '100vw' : 320,
          },
        }}
      >
        <>
          {openRightPanel === 'notifications' && (
            <NotificationRightPanel
              notifications={notifications}
              notificationsToRender={notificationsPerPage}
              isMobile={isMobile}
              handleNotificationsOpen={handleNotificationsOpen}
              handleRightPanelClose={handleRightPanelClose}
              setNotificationsToRender={setNotificationsPerPage}
              setNumberOfPages={setNumberOfPages}
              setLastPage={setLastPage}
            />
          )}
          {openRightPanel === 'laborPanel' && <DemoComponents.RightPanelSecondary />}
        </>
      </Drawer>
      <AppBar hasUnreadNotifications={hasUnreadNotifications} />
      <Box>
        {userPreferencesAlertOpen && (
          <Grid item xs={12} pb={2} pl={4} pr={4}>
            <Alert data-testid='fetch-error-alert' severity='error'>
              {t(`admin.dashboardPage.snackBar.errorFetchingUserPreferences`)}
            </Alert>
          </Grid>
        )}
        {notificationAlert && (
          <Grid item xs={12} pb={2} pl={4} pr={4}>
            <Alert data-testid='fetch-error-alert' severity='error'>
              {t('admin.allNotificationsDialog.errorFetchingNotification')}
            </Alert>
          </Grid>
        )}

        {isLoadingTokenExchange || user.isOrgSwitching || !organization || !packagesSet ? (
          <Grid container alignItems='center' justifyContent='center' data-testid='spinner-container'>
            <CircularProgress />
          </Grid>
        ) : (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
            <Suspense
              fallback={
                <Grid container alignItems='center' justifyContent='center' data-testid='spinner-container'>
                  <CircularProgress />
                </Grid>
              }
            >
              <Routes />
            </Suspense>
          </LocalizationProvider>
        )}

        <NotificationsDialog
          open={notificationsDialogOpen}
          notifications={notifications}
          page={notificationPage}
          itemsPerPage={notificationsPerPage}
          numberOfPages={numberOfPages}
          isFullScreen={isMobile}
          handleClose={handleNotificationsClose}
          handlePageChange={handleNotificationsPageChange}
        />
      </Box>
    </CustomV4Sidebar>
  );
}

export default function Shell(props: ShellProps) {
  const { apiRoot, ncridUrl } = props;
  const history = useHistory();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // on page error navigate home since it can cause an auth loop with the reset otherwise
        history.push('/');
      }}
    >
      <ShellContextProvider>
        <OldShell apiRoot={apiRoot} ncridUrl={ncridUrl} />
      </ShellContextProvider>
    </ErrorBoundary>
  );
}
