import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { isValidPhoneNumber } from 'mui-tel-input';
import { TFunction } from 'react-i18next';
import * as yup from 'yup';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';

import { Employee as EmployeeType, PayrollPeriod } from '@cbo/shared-library';
import { PayrollCalendarFilters } from '@cbo/shared-library/response/calendar.response';
import { DateFilterChip, SingleSelectGroup } from '../../components/GlobalFilterBar/types';
import { getEndDate } from '../../utils/reportingUtils/filterUtils';
import { TEmployeeDetailsRegistrationTask } from '../EmployeeRegistrationPageNew/EmployeeRegistrationHomePage/EmployeeRegistrationHomePage';
import { EmployeeConfigurationsResponse } from '../services/employeeService';
import {
  ActualHoursWorked,
  EmergencyContactFormDataSchema,
  EmploymentHistory,
  EmploymentStatusNew,
  EmployeeNew,
  LaborGroupSchema,
  OvertimeThresholds,
  UpdateEmergencyContactRequestSchema,
  UpdateEmployeePermissionsSchema,
} from '../types';

export const countryCallingCodes = getCountries().map((countryCode) => `+${getCountryCallingCode(countryCode)}`);

const phoneNumberSchema = (validationMessage: string, optional = false, isValidationRequired = true): yup.AnySchema => {
  let baseSchema: yup.AnySchema = yup.string();
  if (optional) {
    baseSchema = yup.string().nullable();
  }
  return baseSchema.test('phoneValidation', validationMessage, (inputValue: string | undefined) => {
    if ((optional && !isValidationRequired) || countryCallingCodes.includes(inputValue ?? '')) {
      return true;
    }
    if (inputValue) {
      return isValidPhoneNumber(inputValue);
    }
    if (!inputValue) return true;
    return false;
  });
};

const emergencyContactDataMapper = (formData: EmergencyContactFormDataSchema) => {
  let relationship;
  if (formData.relationship === 'In-law') {
    relationship = EmployeeType.Relationship.INLAW;
  } else {
    relationship =
      EmployeeType.Relationship[formData.relationship?.toUpperCase() as keyof typeof EmployeeType.Relationship];
  }
  const mappedEmergencyContactData: UpdateEmergencyContactRequestSchema = {
    name: `${formData.firstName} ${formData.lastName}`,
    relationship,
    primaryPhoneNumber: formData.phoneNumber,
    alternatePhoneNumber: formData.altPhoneNumber,
    emailAddress: formData.email,
    formCompletionStatus: EmployeeType.EmployeeFormCompletionStatus.COMPLETE,
  };
  return mappedEmergencyContactData;
};

const employeePermissionsDataWrapper = (data: UpdateEmployeePermissionsSchema): UpdateEmployeePermissionsSchema => {
  const returnData: UpdateEmployeePermissionsSchema = {
    prefersQwertyKeyboard: data.prefersQwertyKeyboard,
    useMagneticCards: data.useMagneticCards,
    posEmployeeId: data.posEmployeeId,
    useFingerPrintScannerForClockIn: data.useFingerPrintScannerForClockIn,
    useFingerPrintScannerForLoginAndManagerApproval: data.useFingerPrintScannerForLoginAndManagerApproval,
  };
  return returnData;
};

const laborGroupDataMapper = (data: LaborGroupSchema[] | undefined) =>
  data
    ? data.map((item) => ({
        label: item.name,
        id: item.id,
        value: item.name,
      }))
    : [];

const createEmployeeRegistrationTasks = (): TEmployeeDetailsRegistrationTask[] => [
  {
    name: 'employeeInformation',
    link: 'employee-information',
  },
  {
    name: 'personalInformation',
    link: 'personal-information',
  },
  {
    name: 'emergencyContact',
    link: 'emergency-contact',
  },
  {
    name: 'certification',
    link: 'employee-certification',
  },
];

const constructFullName = (firstName: string | undefined, lastName: string | undefined) => `${firstName} ${lastName}`;

const formatEmployee = (employees: EmployeeConfigurationsResponse[] | undefined, employeeEmail: string | undefined) => {
  if (employeeEmail) {
    const employee = employees?.find((currentEmployee) => {
      if (!currentEmployee.employeeInformation) return false;
      return currentEmployee.employeeInformation.contactInformation.emailAddress === employeeEmail;
    });
    return employee?.employeeInformation?.contactInformation
      ? constructFullName(
          employee.employeeInformation.contactInformation.firstName,
          employee.employeeInformation.contactInformation.lastName
        )
      : employeeEmail;
  }
  return employeeEmail;
};

const getEmployeeNameUsingEmail = (employees: EmployeeNew[] | undefined, employeeEmail: string | undefined) => {
  if (employeeEmail) {
    const employee = employees?.find((currentEmployee) => {
      if (!currentEmployee.contact.emailAddress) return false;
      return currentEmployee.contact.emailAddress === employeeEmail;
    });
    return employee?.contact ? constructFullName(employee.contact.firstName, employee.contact.lastName) : employeeEmail;
  }
  return employeeEmail;
};

const getUpdatedBy = (
  stringToFormat: string,
  activeEmployees: EmployeeConfigurationsResponse[] | undefined,
  inactiveEmployees: EmployeeConfigurationsResponse[] | undefined,
  pendingEmployees: EmployeeConfigurationsResponse[] | undefined,
  t: (label: string) => string
) => {
  // The NCRUserId is different than a user's email. Stripping string to be just the email.
  let splitStringToFormat = stringToFormat.substring(stringToFormat.indexOf(':') + 1);
  if (splitStringToFormat.split('-').length <= 2) {
    splitStringToFormat = splitStringToFormat.includes('-') ? splitStringToFormat.split('-')[1] : splitStringToFormat;
  }
  // Checking the string is successfully getting a first and last name and return it
  const activeFormat = formatEmployee(activeEmployees, splitStringToFormat);
  const inactiveFormat = formatEmployee(inactiveEmployees, splitStringToFormat);
  const pendingFormat = formatEmployee(pendingEmployees, splitStringToFormat);

  if (activeFormat?.includes(' ')) {
    return activeFormat;
  }
  if (inactiveFormat?.includes(' ')) {
    return inactiveFormat;
  }
  if (pendingFormat?.includes(' ')) {
    return pendingFormat;
  }
  return splitStringToFormat.includes('service-account') ? t('labor.serviceAccount') : splitStringToFormat;
};

const getUpdatedByNew = (
  stringToFormat: string,
  activeEmployees: EmployeeNew[] | undefined,
  inactiveEmployees: EmployeeNew[] | undefined,
  pendingEmployees: EmployeeNew[] | undefined,
  t: (label: string) => string
) => {
  // The NCRUserId is different than a user's email. Stripping string to be just the email.
  let splitStringToFormat = stringToFormat.substring(stringToFormat.indexOf(':') + 1);
  if (splitStringToFormat.split('-').length <= 2) {
    splitStringToFormat = splitStringToFormat.includes('-') ? splitStringToFormat.split('-')[1] : splitStringToFormat;
  }
  // Checking the string is successfully getting a first and last name and return it
  const activeFormat = getEmployeeNameUsingEmail(activeEmployees, splitStringToFormat);
  const inactiveFormat = getEmployeeNameUsingEmail(inactiveEmployees, splitStringToFormat);
  const pendingFormat = getEmployeeNameUsingEmail(pendingEmployees, splitStringToFormat);

  if (activeFormat?.includes(' ')) {
    return activeFormat;
  }
  if (inactiveFormat?.includes(' ')) {
    return inactiveFormat;
  }
  if (pendingFormat?.includes(' ')) {
    return pendingFormat;
  }
  return splitStringToFormat.includes('service-account') ? t('labor.serviceAccount') : splitStringToFormat;
};

export const getHoursWorkedOptions = (hoursWorked: Record<string, ActualHoursWorked>): SingleSelectGroup[] => {
  const options: SingleSelectGroup[] = [];
  Object.entries(hoursWorked).forEach(([key]) => {
    options.push({
      label: key,
    });
  });

  return options;
};

export const getACAHoursWorked = (
  t: TFunction<'translation', undefined>,
  overtimeThresholds: OvertimeThresholds,
  includeLessThan = true
): { hoursWorked: Record<string, ActualHoursWorked>; hoursWorkedOptions: SingleSelectGroup[] } => {
  const maxHoursPossibleInWeek = 168;
  const hoursWorked: Record<string, ActualHoursWorked> = {
    [t('labor.all')]: { lower: 0, higher: maxHoursPossibleInWeek },
    [t('labor.fromXToX', {
      number1: overtimeThresholds.lowerThreshold,
      number2: overtimeThresholds.middleThreshold,
    })]: { lower: overtimeThresholds.lowerThreshold, higher: overtimeThresholds.middleThreshold },
    [t('labor.fromXToX', {
      number1: overtimeThresholds.middleThreshold,
      number2: overtimeThresholds.upperThreshold,
    })]: { lower: overtimeThresholds.middleThreshold, higher: overtimeThresholds.upperThreshold },
    [t('labor.moreThanX', { number: overtimeThresholds.upperThreshold })]: {
      lower: overtimeThresholds.upperThreshold,
      higher: maxHoursPossibleInWeek,
    },
  };

  if (includeLessThan) {
    hoursWorked[t('labor.lessThanX', { number: overtimeThresholds.lowerThreshold })] = {
      lower: 0,
      higher: overtimeThresholds.lowerThreshold,
    };
  }

  return { hoursWorked, hoursWorkedOptions: getHoursWorkedOptions(hoursWorked) };
};

export const getOTHoursWorked = (
  t: TFunction<'translation', undefined>,
  overtimeThresholds: OvertimeThresholds
): { hoursWorked: Record<string, ActualHoursWorked>; hoursWorkedOptions: SingleSelectGroup[] } => {
  const hoursWorked: Record<string, ActualHoursWorked> = {
    [t('labor.all')]: { lower: undefined, higher: undefined },
    [t('labor.fromXToX', {
      number1: overtimeThresholds.lowerThreshold,
      number2: overtimeThresholds.middleThreshold,
    })]: { lower: overtimeThresholds.lowerThreshold, higher: overtimeThresholds.middleThreshold },
    [t('labor.fromXToX', {
      number1: overtimeThresholds.middleThreshold,
      number2: overtimeThresholds.upperThreshold,
    })]: { lower: overtimeThresholds.middleThreshold, higher: overtimeThresholds.upperThreshold },
    [t('labor.moreThanX', { number: overtimeThresholds.upperThreshold })]: {
      lower: overtimeThresholds.upperThreshold,
      higher: undefined,
    },
  };

  return { hoursWorked, hoursWorkedOptions: getHoursWorkedOptions(hoursWorked) };
};

export const getPayrollPeriodOptions = (
  t: TFunction<'translation', undefined>,
  payrollCalendarFilter: PayrollCalendarFilters | undefined
): DateFilterChip[] => {
  if (payrollCalendarFilter) {
    return [
      {
        label: t(`labor.currentPayrollPeriod`),
        dates: [
          dayjs(payrollCalendarFilter[PayrollPeriod.PAY_PERIOD_START_DATE].value),
          dayjs(getEndDate(payrollCalendarFilter[PayrollPeriod.PAY_PERIOD_END_DATE].value)),
        ],
      },
      {
        label: t(`labor.lastPayrollPeriod`),
        dates: [
          dayjs(payrollCalendarFilter[PayrollPeriod.PREVIOUS_PAY_PERIOD_START_DATE].value),
          dayjs(getEndDate(payrollCalendarFilter[PayrollPeriod.PREVIOUS_PAY_PERIOD_END_DATE].value)),
        ],
      },
    ];
  }
  return [];
};

const mapActivationStatusToEmploymentStatus = (
  employeeHistory?: EmploymentHistory | null
): EmploymentStatusNew | null => {
  const activationStatus = employeeHistory?.employmentStatus;
  let mappedStatusValue;
  switch (activationStatus) {
    case 'PENDING':
      mappedStatusValue = EmploymentStatusNew.PENDING;
      break;
    case 'TERMINATED':
      mappedStatusValue = EmploymentStatusNew.TERMINATED;
      break;
    case 'LEAVE_OF_ABSENCE':
      mappedStatusValue = EmploymentStatusNew.LEAVE_OF_ABSENCE;
      break;
    case 'HIRED':
      mappedStatusValue = EmploymentStatusNew.HIRED;
      break;
    default:
      mappedStatusValue = null;
      break;
  }
  return mappedStatusValue;
};

export const getLatestEmployeeHistoryItem = (employee?: EmployeeNew | null) =>
  employee?.employmentHistory.sort((a, b) => {
    if (isNil(a.effectiveDate) || isNil(b.effectiveDate)) {
      return 0;
    }
    return new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime();
  })[0];

export const isPhoneNumberWithOnlyCountryCode = (phoneNumber: string) => countryCallingCodes.includes(phoneNumber);

const LaborUtilities = {
  phoneNumberSchema,
  emergencyContactDataMapper,
  constructFullName,
  getUpdatedBy,
  formatEmployee,
  employeePermissionsDataWrapper,
  laborGroupDataMapper,
  createEmployeeRegistrationTasks,
  mapActivationStatusToEmploymentStatus,
  getUpdatedByNew,
  getLatestEmployeeHistoryItem,
  isPhoneNumberWithOnlyCountryCode,
};

export default LaborUtilities;
